.App {
  align-items: center;
  background-image: url("./bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  height: 100vh;
  justify-content: center;
  padding: 0 0.5em;
  text-align: center;
}

.App-icon {
  height: 25vh;
}

.App-logo {
  width: 90vw;
}

.App-social-networks {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-social-networks li {
  padding-right: 1em;
}

.App-social-networks img {
  width: 38px;
  height: 38px;
  margin-top: 1rem;
}

.App footer {
  justify-self: flex-end;
  margin: auto 0 1rem;
}

.App a {
  color: #61dafb;
}


@media screen and (min-width: 1040px) {

  .icons-container {
    display: flex;
    flex-direction: column;
  }

  .icons-container img:nth-child(1n) {
    margin-bottom: 1.5rem;

  }

  .App-icon {
    margin: 4rem auto 0;
  }

  .App-logo {
    width: 80vh;
  }

  .App-social-networks img {
    width: 50px;
    height: 50px;
    margin-top: 1rem;
  }

  .Copyright {
    font-size: 1.4rem;
  }

  main p {
    margin: 0 auto;
    width: 70%;
  }
}
